import { combineReducers } from 'redux';
import customers from './customers'          
import detailsReducer from './detailsReducer'                                                 
import loadreducer from './loadreducer'
import {configurationContainerReducer,configurationValuesReducer, changeConfigurationReducer} from './configurationContainerReducer'
import bicsFormReducer from './formReducer'
import { reducer as formReducer } from 'redux-form'
import history from './history'
import toasts from './toasts'
import spinner from './loadingSpinnerReducer'


const rootReducer = combineReducers({
   customers, 
   configurations : configurationContainerReducer,
   configurationValues: configurationValuesReducer,
   form: formReducer,
   details: detailsReducer,
   bicsFormReducer,
   history,
   loadreducer,
   selectedConfiguration: changeConfigurationReducer,
   toasts,
   spinner
});
 
export default rootReducer;