import React from 'react'
import { FieldArray } from 'redux-form'

import RenderConfigurations from '../rendercomponents/renderconfigurations'


class RenderConfigurationBody extends React.Component {
    constructor(props) {
        super(props);

        const configurationValues = props.configurationValues
        this.state = {
            allowEditing: props.allowEditing,
            configurationValues: props.configurationValues,
            activeTab: 0,
            fields: props.fields,
            invoiceTypes: configurationValues.invoiceTypes,
            aggregationTypes: configurationValues.aggregationTypes,
            mappingTypes: configurationValues.mappingTypes,
            weekDays: configurationValues.weekDays,
        }
    }
    tabClick = (index) => {
        this.setState({ activeTab: index })
    }
    render() {
        return (
            <div className="row">
                <FieldArray name="configurations" allowEditing={this.state.allowEditing} component={RenderConfigurations} configurationValues={this.state.configurationValues} activeTab={this.state.activeTab} handleTabClick={this.tabClick} copyConfigurationClick={this.copyConfiguration} />
            </div>)
    }
};

export default RenderConfigurationBody

