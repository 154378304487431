const spinner = (state = {loading: false}, action) => {
    switch (action.type) {
        case 'REQUEST_STARTED':
            return { loading: true }
        case 'REQUEST_DONE':
            return { loading: false }
        default:
            return state
    }
};

export default spinner;