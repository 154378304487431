import { bicsConfig } from "../config/config"

export const REQUEST_FAILED = 'REQUEST_FAILED'
export const RECEIVE_INITALSTATE = 'RECEIVE_INITALSTATE'
export const REQUEST_INITALSTATE = 'REQUEST_INITALSTATE'

/*
const requestState = () => {
    return {
        type: REQUEST_INITALSTATE
    }
}
*/
const receiveState = (state,status) => {
    return {
        type: RECEIVE_INITALSTATE,
        state: state,
        status: status
    }
}
const requestFailed = (error, status, statusText) => {
    return {
        type: REQUEST_FAILED,
        error: error,
        status: status,
        statusText: statusText
    }
}


export const loadInitialState = (version = 0) => {

    function handleErrors(response) {
        if (!response.ok) {
            throw new Error({ status: response.status, statusText: response.statusText });
        }
        return response.json();
    }


    return dispatch => { /* process.env.API_URL + "/v1/contract", { */
        
      console.log("bicsConfig.apiUrl", bicsConfig.apiUrl);
      
        dispatch({type: 'REQUEST_STARTED'})

        return fetch(bicsConfig.apiUrl + "/v1/contract/" + version, {
            method: 'get',
            headers: {
                "accept": "application/json",
                "authorization": "Bearer " + localStorage.getItem('access_token')
            },
        })
            .then(handleErrors)
            .then((result) => {
                dispatch(receiveState(JSON.parse(result), 200))
                dispatch({type: 'REQUEST_DONE'})
            })
            .catch((error) => {
                console.log(error)
                if(!error.status)
                {
                    error.status = 500
                }

                dispatch(requestFailed(error, error.status, error.statusText))
                dispatch({type: 'REQUEST_DONE'})
            });
    }
}

