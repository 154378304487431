import React from 'react';
import {connect} from 'react-redux'

import { loadInitialState } from '../actions/initialState'

const mapStateToProps = state => {
  return {
    history: state.history.sort((a, b) => parseInt(b.version, 10) - parseInt(a.version, 10)).slice(0, 4)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadOldVersionClick: (history) => {
      dispatch(loadInitialState(history.version))
    }
  }
}

const History = ({history, loadOldVersionClick}) => {
  return (<div className="col col-xs-6">
  <b> Version history</b>
  <table className="table table-fluid">
    <thead>
      <tr>
        <td>
          Version
          </td>
        <td>
          Edited by
          </td>
        <td>
          Date
          </td>
      </tr>
    </thead>
    <tbody>
      {history.map(function (d, idx) {
        return (
          <tr key={idx}  onClick={(e) => {
            e.preventDefault();
            loadOldVersionClick(d);
          }} style={{cursor: "pointer"}}>
            <td>{d.version}</td>
            <td>{d.editedBy}</td>
            <td>{d.date}</td>
          </tr>
        )
      })}
    </tbody>
  </table>
</div>)
}


export default connect(mapStateToProps, mapDispatchToProps)(History)