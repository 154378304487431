
import PropTypes from "prop-types";
import React, { Component } from "react";

class Toast extends Component {
  render() {
    return (
      <div className={this.props.className + " alert alert-dismissible toast"}>
        <a onClick={this.props.onDismissClick} className="close" aria-label="close">&times;</a>
        {this.props.text}
      </div>
    );
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    setTimeout(function(){
      this.props.onDismissClick()
    }.bind(this), this.props.dissmissTimeout || 5000);
  }
}

Toast.propTypes = {
  className: PropTypes.string.isRequired,
  onDismissClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default Toast;