import React from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import Label from './label'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state) => {
  return {
    s: state,
    configurations: selector(state, `configurations`)
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

const RenderCustomers = (props) => {
  const { s, configurations, fields, meta: { error }, allowEditing } = props

  const printConfigurationName = (id) => {
    const cId = selector(s, id);

    var config = configurations.find(x => x.id === cId);
    if (!config)
      return "Not FOUND"

    return config.basicSettings.description
  }

  return (
    <div className="row">
      <table className="table table-fluid table-striped">
        <thead>
          <tr>
            <td colSpan="4">
              <b>Edit customers</b>
          </td>
          </tr>
          <tr><td>Customer number</td><td>Customer name</td><td>Configuration</td><td style={{ textAlign: 'right' }}>#</td></tr>
        </thead>

        <tbody>
          {fields.map(function (customer, index) {
            return (
              <tr key={index}>
                <td>
                  <Label value={`${customer}.number`} />
                </td>
                <td>
                  <Label value={`${customer}.name`} />
                </td>
                <td>
                  {printConfigurationName(`${customer}.configurationId`)}
                </td>
                <td>
                  {allowEditing &&
                    <div>
                      <button type="button" className="btn btn-danger pull-right btn-sm" onClick={e => {
                        if (window.confirm('Are you sure you want to delete this Customer?')) {
                          fields.remove(index)
                        }
                      }} >
                        <span className="glyphicon glyphicon-trash"></span>
                      </button>
                    </div>}

                </td>
              </tr>
            )
          })}
          {error && <tr><td colSpan="4"><span className="is-invalid-help-text">{error}</span></td></tr>}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderCustomers)