import React from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {
  return {
    text: selector(state, ownProps.value)
  }
}

const Label = ({text}) => {
  return (
    <span>
      {text}
    </span>
  )
}

export default connect(mapStateToProps)(Label)