import React from 'react'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import RenderAggregateSettings from '../rendercomponents/renderaggregatesettings'
import RenderHeaderMappingSingleInvoiceSettings from '../rendercomponents/renderheadermappingsingleinvoicesettings'
import RenderHeaderMappingCollectiveInvoiceSettings from '../rendercomponents/renderheadermappingcollectiveinvoicesettings'
import RenderFrequencySettings from '../rendercomponents/renderfrequencysettings'
import RenderCollectByFieldsCollectiveSettings from '../rendercomponents/rendercollectbyfieldscollectivesettings'
import RenderConfigurableFieldSettings from '../rendercomponents/renderconfigurablefields'
import RenderBasicSettings from '../rendercomponents/renderbasicsettings'
import RenderSpecificationSettings from '../rendercomponents/renderspecificationsettings'
import Label from '../rendercomponents/label'


const RenderConfigurations = (props) => {
    const { s, fields, meta: { error }, activeTab, handleTabClick, allowEditing, addedCustomers } = props


    function uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const configurationId = (id) => {
        const cId = selector(s, id);
        return cId;
    }

    const { invoiceTypes,
        aggregationTypes,
        mappingTypes,
        weekDays,
        specificationTypes,
        configurableFieldsTypes } = props.configurationValues

    return (<div>

        <ul className="nav nav-tabs clearfix">
            {fields.map((config, index) => (
                <li className={activeTab == index ? 'active' : ''} key={`${config}.id`} onClick={(e) => { e.preventDefault(); handleTabClick(index) }}>
                    <a href="#">
                        <Label value={`${config}.basicSettings.description`} />
                    </a>
                </li>
            ))}
            {allowEditing && <li className="pull-right">
                <button className="btn btn-success btn-sm" type="button" onClick={() => {
                    fields.push({
                        basicSettings: {
                            description: 'Config ' + (fields.length + 1), invoiceType: invoiceTypes[0].value
                        },
                        id: uuid(),
                        aggregationSettings: {
                            "aggregationType": "2"
                        },
                        configurableFieldSettings: {
                            meetingType: {
                                englishLanguage: "Meeting type",
                                localLanguage: "Meeting type",
                            }
                        },
                        frequencySettings: {
                            weeklyOn: -1,
                            monthlyOn: -1
                        }
                    })
                    handleTabClick(fields.length)
                }}><span className="glyphicon glyphicon-plus"></span></button>
            </li>}
        </ul>
        {fields.map((config, index) => (
            <div style={{ display: activeTab == index ? 'block' : 'none' }} key={`${config}.id`}>
                <div className="row">
                    <div className="col col-xs-12">
                        <h4> {activeTab == index}
                            <Label value={`${config}.basicSettings.description`} />

                            {allowEditing &&
                                <div>
                                    <button style={{ marginLeft: "5px" }} type="button" className="btn btn-danger pull-right btn-sm" onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this Config?')) {
                                            var customerWithThisConfig = addedCustomers.find(customer => customer.configurationId == configurationId(`${config}.id`))

                                            if (customerWithThisConfig) {
                                                window.alert('There are customers connected to this configuration. To be able to delete this configuration please remove connected customers.')
                                                return;
                                            }

                                            fields.remove(index);
                                            handleTabClick(fields.length - 2)
                                        }
                                    }
                                    }>
                                        <span className="glyphicon glyphicon-trash"></span>
                                    </button>


                                    <button type="button" className="btn btn-success pull-right btn-sm" onClick={() => {
                                        var copyJSON = JSON.stringify(fields.get(index));
                                        var copy = Object.assign({}, JSON.parse(copyJSON))
                                        copy.id = uuid();
                                        copy.basicSettings.description += " (copy) "
                                        fields.push(copy)
                                        handleTabClick(fields.length)
                                    }
                                    }>
                                        <span className="glyphicon glyphicon-copy"></span>
                                    </button>
                                </div>
                            }

                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-4">
                        <RenderBasicSettings selector={`${config}.basicSettings`} invoiceTypes={invoiceTypes} aggregationSettingsSelector={`${config}.aggregationSettings`} />
                    </div>
                    <div className="col col-xs-4">
                        <RenderSpecificationSettings aggregationSettingsSelector={`${config}.aggregationSettings`} selector={`${config}.specificationSettings`} specificationTypes={specificationTypes} />
                    </div>
                    <div className="col col-xs-4">
                        <RenderAggregateSettings specificationSettingsSelector={`${config}.specificationSettings`} basicSettingsSelector={`${config}.basicSettings`} selector={`${config}.aggregationSettings`} aggregationTypes={aggregationTypes} mappingTypes={mappingTypes} />
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-6">
                        <RenderConfigurableFieldSettings selector={`${config}.configurableFieldSettings`} configurableFieldsTypes={configurableFieldsTypes} allowEditing={allowEditing} />
                    </div>
                    <div className="col col-xs-6">
                        <RenderHeaderMappingSingleInvoiceSettings basicSettings={`${config}.basicSettings`} selector={`${config}.headerMappingSingleInvoiceSettings`} mappingTypes={mappingTypes} />
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-6">
                        <RenderCollectByFieldsCollectiveSettings selector={`${config}.collectByFieldsSettings`} mappingTypes={mappingTypes} isCollectiveSelector={`${config}.basicSettings.invoiceType`} />
                    </div>
                    <div className="col col-xs-6">
                        <RenderHeaderMappingCollectiveInvoiceSettings collectByFiledsSelector={`${config}.collectByFieldsSettings`} selector={`${config}.headerMappingCollectiveInvoiceSettings`} mappingTypes={mappingTypes} isCollectiveSelector={`${config}.basicSettings.invoiceType`} />
                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-6">
                        <RenderFrequencySettings selector={`${config}.frequencySettings`} weekDays={weekDays} isCollectiveSelector={`${config}.basicSettings.invoiceType`} />
                    </div>
                </div>
            </div>
        ))}
        {error && <li><span className="is-invalid-help-text">{error}</span></li>}
    </div>)
}

const selector = formValueSelector('fieldArrays')

const mapDispatchToProps = dispatch => {
    return {

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        addedCustomers: selector(state, 'customers'),
        s: state
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderConfigurations)