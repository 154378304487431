import React from 'react';
import { connect } from 'react-redux'
import History from '../components/history'

const mapStateToProps = state => {
  return {
    details: state.details
  }
}

const Details = ({ details }) => (
  <div className="row">

    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">{details.coorId} - {details.agreementName}</h3>
      </div>
      <div className="panel-body">
        <div className="col col-xs-6">
          <b>Details</b>
          <table className="table table-fluid">
            <thead>
              <tr>
                <td>
                  Description
          </td>
                <td>
                  Value
          </td>

              </tr>
            </thead>
            <tbody>
              <tr><td>Orbit id</td><td>{details.orbitId}</td></tr>
              <tr><td>Deployed in Orbit</td><td>{details.externalDeployed.toString()}</td></tr>
              <tr><td>Country</td><td>{details.country}</td></tr>
              <tr><td>Company</td><td>{details.company}</td></tr>
            </tbody>
          </table>
        </div>
        <History />
      </div>
    </div>


  </div>
);


export default connect(
  mapStateToProps
)(Details)

