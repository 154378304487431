import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { renderField, errorMessage } from './select'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {
    const readonly = selector(state, `${ownProps.aggregationSettingsSelector}.aggregationType`) == 1;
    
    return {
        readonly: readonly
    }
}


const RenderSpecificationSettings = ({ selector, specificationTypes, aggregationSettingsSelector, readonly }) => {
    return (<div className="settings">
        <b>SpecificationSettings</b>

        <div>
            {specificationTypes.map((type, index) => (
                <div className="radio" key={index}>
                    <label>
                        <Field disabled={readonly} name={`${selector}.specificationSetting`} component="input" type="radio" value={type.value.toString()} /> {type.name}
                    </label>
                </div>
            ))}
            <Field name={`${selector}.specificationSetting`} component={errorMessage} />
            <div>
                <Field name={`${selector}.specificationEmail`}
                    type="text"
                    component={renderField}
                    label="Specification e-mail" />
            </div>
        </div>

    </div>)
}


export default connect(mapStateToProps)(RenderSpecificationSettings);

