import React from 'react'
import { Field, Fields, FieldArray, reduxForm, arrayPush, formValueSelector, change, arrayMove } from 'redux-form'
import { connect } from 'react-redux'
import { renderField, renderCheckbox, renderTextBox, renderReadOnlyTextBox } from './select'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {
    return {
        isOfTypeList: selector(state, `${ownProps.selector}.type`) == 1
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        addValueClick: (selector) => {
            dispatch(arrayPush('fieldArrays', selector, { localLanguage: '', englishLanguage: '' }))
        },
        clearField: () => {
            var isMeetingType = props.isMeetingType == undefined ? false : props.isMeetingType;
            dispatch(change('fieldArrays',
                props.selector,
                {
                    type: "0",
                    values: null,
                    englishLanguage: isMeetingType ? "Meeting type" : null,
                    localLanguage: isMeetingType ? "Meeting type" : null,
                    isRequired: null,
                    isMeetingType: isMeetingType
                }))
        }
    }
}

const RenderConfigurableField = ({ initialValues, name, selector, configurableFieldsTypes, isOfTypeList, addValueClick, show, isMeetingType, clearField, disableFieldAndShowLabel, label }) => {

    return (show &&
        <div id="configurable-field-background">
            <div id="configurable-field-wrapper">
                <div className="panel panel-default">
                    <div className="panel-heading clearfix">
                        <h3 className="panel-title pull-left">{name}</h3>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm pull-right"
                            onClick={() => {
                                clearField()
                            }}
                        ><span className="glyphicon glyphicon-trash"></span></button>

                    </div>
                    <div className="panel-body">
                        <table className="table table-fluid">
                            <tbody>
                                <tr>
                                    <td>
                                        <Field name={`${selector}.showInCheckout`} type="checkbox" component={renderCheckbox} label="Show in checkout" />
                                    </td>
                                    <td>
                                        <Field name={`${selector}.isRequired`} type="checkbox" component={renderCheckbox} label="Required" />
                                    </td>
                                </tr>

                                {disableFieldAndShowLabel ?
                                    <tr>
                                        <td>
                                            <Field name={`${selector}.localLanguage`} type="text" component={renderTextBox} label={label} />
                                        </td>
                                        <td>
                                            <Field name={`${selector}.englishLanguage`} type="text" component={renderTextBox} label={label} />
                                        </td>
                                    </tr>
                                    : <tr>
                                        <td>
                                            <Field name={`${selector}.localLanguage`} type="text" component={renderTextBox} label="Description in local language" />
                                        </td>
                                        <td>
                                            <Field name={`${selector}.englishLanguage`} type="text" component={renderTextBox} label="Description in english" />
                                        </td>
                                    </tr>
                                }

                            </tbody>
                        </table>

                        <table className="table table-fluid">
                            <tbody>
                                <tr>

                                    {configurableFieldsTypes.map((type, index) => (
                                        <td key={index}>
                                            <div className="radio">
                                                <label>
                                                    <Field name={`${selector}.type`} component="input" type="radio" value={type.value.toString()} /> {type.name}
                                                </label>
                                            </div>
                                        </td>
                                    ))}
                                    <td>
                                        {isOfTypeList &&
                                            <button type="button" onClick={() => addValueClick(`${selector}.values`)} className="btn btn-success btn-sm pull-right">
                                                <span className="glyphicon glyphicon-plus"></span></button>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>
                        <table className="table table-fluid">
                            {isOfTypeList &&
                                <FieldArray name={`${selector}.values`} component={renderListValue} />
                            }
                        </table>
                    </div>
                    <div className="panel-footer text-right">

                    </div>
                </div>

            </div>
        </div>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(RenderConfigurableField);


const renderListValue = ({ fields, meta: { error, touched } }) => {

    const moveDown = (fields, index) => {
        //console.log(fields, index)
        fields.move(index, index + 1)
    }
    const moveUp = (fields, index) => {
        //console.log(fields, index)
        fields.move(index, index - 1)
    }
    return (

        <tbody>
            {fields.map((item, index) => (
                <tr key={index}>
                    <td>
                        <Field
                            name={`${item}.localLanguage`}
                            type="text"
                            component={renderTextBox}
                            label={`Local language`}
                        />
                    </td>
                    <td>
                        <Field
                            name={`${item}.englishLanguage`}
                            type="text"
                            component={renderTextBox}
                            label={`English language`}
                        />
                    </td>
                    <td>
                        <button
                            type="button"
                            className="btn btn-danger btn-xs button-cField-list  pull-right"
                            onClick={() => fields.remove(index)}
                        ><span className="glyphicon glyphicon-trash"></span></button>
                        <button
                            type="button"
                            className="btn btn-success btn-xs button-cField-list pull-right"
                            onClick={() => fields.splice(index, 0, { localLanguage: '', englishLanguage: '' })}
                        > <span className="glyphicon glyphicon-plus"></span></button>
                        {index + 1 !== fields.length &&
                            <button
                                type="button"
                                className="btn btn-secondary btn-xs button-cField-list  pull-right"
                                onClick={() => moveDown(fields, index)}
                            > <span className="glyphicon glyphicon-arrow-down"></span></button>
                        }
                        {index !== 0 &&
                            <button
                                type="button"
                                className="btn btn-secondary btn-xs button-cField-list  pull-right"
                                onClick={() => moveUp(fields, index)}
                            > <span className="glyphicon glyphicon-arrow-up"></span></button>
                        }
                    </td>
                </tr>
            ))}

            {error && <tr><td colSpan="3"><span className="is-invalid-help-text">{error}</span></td></tr>}

        </tbody>
    )
}