import React from 'react'
import { Field, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import { renderSelect, renderField } from '../rendercomponents/select'

const RenderBasicSettings = ({ selector, invoiceTypes, changeInvoiceType, aggregationSettingsSelector, isCollective }) => {

    return (
        <div className="settings">
            <b>Basic settings</b>
            <table className="table table-fluid">
                <tbody>
                    <tr>
                        <td>
                            <Field
                                name={`${selector}.description`}
                                type="text"
                                component={renderField}
                                label="Description"
                            /></td>
                    </tr>
                    <tr>
                        <td>
                            <label>Invoice type</label>
                            <div>
                                <Field name={`${selector}.invoiceType`} component={renderSelect} options={invoiceTypes} onChange={e => changeInvoiceType(e, aggregationSettingsSelector)} />
                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            {isCollective && <Field
                                name={`${selector}.overrideAmount`}
                                type="number"
                                component={renderField}
                                label="Override to single if amount"
                            />}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}


const selector = formValueSelector('fieldArrays')
const mapStateToProps = (state, ownProps) => {
    return {
        isCollective: selector(state, `${ownProps.selector}.invoiceType`) == 1,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        changeInvoiceType: (event, aggregationSettingsSelector) => {
            if (event.target.value == 2) {
                dispatch(change('fieldArrays', `${aggregationSettingsSelector}.aggregationType`, 2))
            }
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RenderBasicSettings)