import React from 'react'

import RenderConfigurableField from './renderconfigurablefield'


class RenderConfigurableFieldSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selector: props.selector,
            configurableFieldsTypes: props.configurableFieldsTypes,
            showField1: false,
            showField2: false,
            showField3: false,
            showMeetingType: false,
            showMeetingParticipants: false,
            allowEditing: props.allowEditing
        }
    }
    toggleField = (field) => {

        this.setState({
            showField1: false,
            showField2: false,
            showField3: false,
            showMeetingType: false,
            showMeetingParticipants: false
        })

        var value = this.state[field];
        this.setState({ [field]: !value })
    }
    render() {
        return (
            <div className="settings">
                <b>Configurable field settings</b>
                
                <table className="table table-fluid">
                    <tbody>
                        <tr>
                            <td>Configurble field 1</td>
                            <td>
                                {this.state.allowEditing &&
                                    <button type="button" className="btn btn-success pull-right btn-sm" onClick={e => {
                                        e.preventDefault()
                                        this.toggleField('showField1')
                                    }}>
                                        <span className="glyphicon glyphicon-pencil"></span>
                                    </button>
                                }
                            </td>

                        </tr>
                        <tr>
                            <td>Configurble field 2</td>
                            <td>
                                {this.state.allowEditing &&
                                    <button type="button" className="btn btn-success pull-right btn-sm" onClick={e => {
                                        e.preventDefault()
                                        this.toggleField('showField2')
                                    }}>
                                        <span className="glyphicon glyphicon-pencil"></span>
                                    </button>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Configurble field 3</td>
                            <td>
                                {this.state.allowEditing &&
                                    <button type="button" className="btn btn-success pull-right btn-sm" onClick={e => {
                                        e.preventDefault()
                                        this.toggleField('showField3')
                                    }}>
                                        <span className="glyphicon glyphicon-pencil"></span>
                                    </button>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Meeting type</td>
                            <td>
                                {this.state.allowEditing &&
                                    <button type="button" className="btn btn-success pull-right btn-sm" onClick={e => {
                                        e.preventDefault()
                                        this.toggleField('showMeetingType')
                                    }}>
                                        <span className="glyphicon glyphicon-pencil"></span>
                                    </button>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Meeting Participants</td>
                            <td>
                                {this.state.allowEditing &&
                                    <button type="button" className="btn btn-success pull-right btn-sm" onClick={e => {
                                        e.preventDefault()
                                        this.toggleField('showMeetingParticipants')
                                    }}>
                                        <span className="glyphicon glyphicon-pencil"></span>
                                    </button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>

                <RenderConfigurableField name="Edit Configurable Field 1" selector={`${this.state.selector}.field1`} configurableFieldsTypes={this.state.configurableFieldsTypes} show={this.state.showField1} close={() => this.toggleField('showField1')} />
                <RenderConfigurableField name="Edit Configurable Field 2" selector={`${this.state.selector}.field2`} configurableFieldsTypes={this.state.configurableFieldsTypes} show={this.state.showField2} close={() => this.toggleField('showField2')} />
                <RenderConfigurableField name="Edit Configurable Field 3" selector={`${this.state.selector}.field3`} configurableFieldsTypes={this.state.configurableFieldsTypes} show={this.state.showField3} close={() => this.toggleField('showField3')} />
                <RenderConfigurableField name="Edit meeting type" selector={`${this.state.selector}.meetingType`} configurableFieldsTypes={this.state.configurableFieldsTypes} show={this.state.showMeetingType} close={() => this.toggleField('showMeetingType')} isMeetingType={true} label="Meeting Type" disableFieldAndShowLabel={true} />
                <RenderConfigurableField name="Edit meeting participants" isOfTypeList={false} selector={`${this.state.selector}.meetingParticipants`} configurableFieldsTypes={this.state.configurableFieldsTypes.filter(o => o.name !== "List")} show={this.state.showMeetingParticipants} close={() => this.toggleField('showMeetingParticipants')} label="Meeting Participants" />

            </div>
        )
    }
}

export default RenderConfigurableFieldSettings;



