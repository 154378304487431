
const details = (state = [], action) => {
    switch (action.type) {
        case 'RECEIVE_INITALSTATE':
            return action.state.details;
        case 'NEW_VERSION':
            //var newState = Object.assign({}, state, {version: action.versionNumber});
            return {...state, version: action.versionNumber}
        default:
            return state
    }
};

export default details;