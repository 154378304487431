import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { renderSelect, errorMessageObjectLevel } from '../rendercomponents/select'

const selector = formValueSelector('fieldArrays')




const mapStateToProps = (state, ownProps) => {

    return {
        isCollective: selector(state, `${ownProps.isCollectiveSelector}`) == 1,
        field1: selector(state, `${ownProps.selector}.field1`),
        field2: selector(state, `${ownProps.selector}.field2`),
        field3: selector(state, `${ownProps.selector}.field3`)
    }
}

const RenderCollectByFieldsCollectiveSettings = ({ selector, mappingTypes, isCollective, field1, field2, field3 }) => {

    return (isCollective &&
        <div className="settings">
            <b>Collect by fields (only if collective)</b>

            <Field name={`${selector}.objectError`} component={errorMessageObjectLevel} />

            <table className="table table-fluid">
                <tbody>
                    <tr>
                        <td>Field 1:</td><td>
                            <Field name={`${selector}.field1`} component={renderSelect} options={mappingTypes.filter(type => type.value != field2 && type.value != field3)} emptyOptionValue="true" />
                        </td>
                    </tr>
                    <tr>
                        <td>Field 2:</td><td>
                            <Field name={`${selector}.field2`} component={renderSelect} options={mappingTypes.filter(type => type.value != field1 && type.value != field3)} emptyOptionValue="true" />
                        </td>
                    </tr>
                    <tr>
                        <td>Field 3:</td><td>
                            <Field name={`${selector}.field3`} component={renderSelect} options={mappingTypes.filter(type => type.value != field2 && type.value != field1)} emptyOptionValue="true" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default connect(mapStateToProps)(RenderCollectByFieldsCollectiveSettings)
