import React from 'react';
import { connect } from 'react-redux'
import './bootstrap/css/bootstrap.min.css'
import './App.css';
//import { postForm } from './actions/formActions'
//import Home from './pages/home'
import LoadingSpinner from './components/LoadingSpinner'
import Unauthorized from './components/Unauthorized'
import InternalServerError from './components/InternalServerError'

import { submit, getFormSyncErrors } from 'redux-form'

import FieldArraysForm from './components/basicexample'

import Details from './components/details'
//import CustomersList from './components/customer/customerList'
import Toasts from './components/toasts/toasts'

import { newVersion } from './actions/version'
import { addToast } from './actions/toasts'


import JsonOutput from './components/jsonoutput'
import { bicsConfig } from './config/config';

let App = props => {
  const {
    loading,
    showResults,
    user,
    saveWorkingCopy,
    saveAndPublish,
    deployed,
    status,
    allowEditing,
    errors,
    currentVersion } = props;


  const renderComponent = (status) => {
    switch (status) {
      case 200:
        return (<div>
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header">
                <span className="navbar-brand">
                  User: {user}
                </span>
              </div>
              {!allowEditing &&
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <a href="/"> <b>You are in readonly mode, please click here to return to edit mode (Viewing version: {currentVersion})</b></a>
                  </li>
                </ul>}
              {allowEditing &&
                <ul className="nav navbar-nav navbar-right">
                  <li><a href="#" className="" onClick={() => saveWorkingCopy(errors)}>Save working copy</a></li>
                  {deployed && <li><a href="#" className="" onClick={() => {
                    if (window.confirm('Are you sure you want to publish this contract configuration to Orbit?')) {
                      saveAndPublish(errors)
                    }
                  }}>Save and publish</a></li>}
                </ul>}
            </div>
          </nav>
          <div className="container">
            <Details />
            <FieldArraysForm onSubmit={showResults} />
            <JsonOutput />
          </div>
          <Toasts />
        </div>)
      case 401:
        return <Unauthorized />
      case 500:
        return <InternalServerError />
      default:
        return (<div>There is an error with the application, please try again later.</div>)
    }
  }

  return !loading ? (
    <div className="App">
      {renderComponent(status)}
    </div>
  ) : <LoadingSpinner />;
}



const mapDispatchToProps = (dispatch, ownProps) => {

  function handleErrors(response) {
    if (!response.ok) {
      throw new Error({ status: response.status, statusText: response.json() });
    }
    return response.json();
  }

  return {
    saveWorkingCopy: (errors) => {

      if (Object.keys(errors).length > 0) {
        alert("There are errors in the form that have to be fixed before saving!")
        return;
      }

      window.publish = false;

      dispatch(submit('fieldArrays'))
    },
    saveAndPublish: (errors) => {

      if (Object.keys(errors).length > 0) {
        alert("There are errors in the form that have to be fixed before saving!")
        return;
      }

      window.publish = true;
      dispatch(submit('fieldArrays'))
    },
    showResults: async (form) => {
      console.log("bicsConfig.apiUrl", bicsConfig.apiUrl);
      form.publish = window.publish
      fetch(bicsConfig.apiUrl + "/v1/contract", {
        method: 'post',
        headers: {
          "accept": "application/json",
          "content-type": "application/json",
          "authorization": "Bearer " + localStorage.getItem('access_token')
        },
        body: JSON.stringify(form)
      })
        .then(handleErrors)
        .then((result) => {
          result = JSON.parse(result)

          if (result.newFileVersion) {
            dispatch(newVersion(result.newFileVersion.version, result.newFileVersion.editedBy, result.newFileVersion.date));
          }

          result.messages.forEach(result => {
            dispatch(addToast({ text: result.message, className: result.isSuccess ? 'alert-success' : 'alert-danger' }))
          });
        }
        )
        .catch((status, error) => {
          dispatch(addToast({ text: "", className: 'alert-danger' }))
        });
    }
  }
}

const mapStateToProps = (state) => {
  var errors = getFormSyncErrors('fieldArrays')(state)
  return {
    loading: state.spinner.loading,
    status: state.loadreducer.status,
    authorized: state.loadreducer.status !== 401,
    user: state.details.user,
    deployed: state.details.externalDeployed,
    allowEditing: state.loadreducer.allowedEditing,
    currentVersion: state.loadreducer.currentVersion,
    errors
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
