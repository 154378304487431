let id = 0;

const defaultOptions = {
  className: 'alert-info'
};

export default function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: id++
  }
}