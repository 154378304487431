import React from 'react';
import { connect } from 'react-redux'
import { getFormValues, getFormSyncErrors } from 'redux-form'
import { bicsConfig } from '../config/config';

//const JsonOutput = ({ output, errors }) => process.env.REACT_APP_ENVIRONMENT == "production" ? null : (
const JsonOutput = ({ output, errors }) => bicsConfig.environment == "production" ? null : (
    <div>
        <div className="col col-xs-6">
            <h2>Values</h2>
            <pre>
                {JSON.stringify(output, null, 2)}
            </pre>
        </div>
        <div className="col col-xs-6">
            <h2>Errors</h2>
            <pre>
                {JSON.stringify(errors, null, 2)}
            </pre>
        </div>
    </div>
)

const mapStateToPros = state => {
    var output = getFormValues('fieldArrays')(state);
    output.details = state.details
    let index = state.details.version + 1

    var errors = getFormSyncErrors('fieldArrays')(state)
  
    return {
        output: output,
        errors: errors,
        index: index
    }
}

export default connect(mapStateToPros)(JsonOutput)