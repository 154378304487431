
const bicsFormReducer = (state = [], action) => {
    switch (action.type) {
        case 'POST_FORM':
            return state;
        default:
            return state
    }
};

export default bicsFormReducer;