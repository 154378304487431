import React from 'react'
import { Field, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import { renderSelect, errorMessage } from '../rendercomponents/select'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {
    return {
        isCollective: selector(state, `${ownProps.isCollectiveSelector}`) == 1
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeEventResetOtherValue: (valueToReset) => {
            dispatch(change('fieldArrays', valueToReset, -1))
        }
    }
}

const RenderFrequencySettings = ({ selector, weekDays, isCollective, changeEventResetOtherValue }) => {
    const dates = Array.apply(null, { length: 31 }).map(Number.call, Number).map(function (item, key) {
        return { value: item +1, name: key + 1 }
    })
    return (isCollective && <div className="settings">
        <b>Frequency settings (only if collective)</b>
        <Field name={`${selector}.weeklyOn`} component={errorMessage} />
        <table className="table table-fluid">
            <tbody>
                <tr>
                    <td>Weekly on</td>
                    <td>
                        <Field name={`${selector}.weeklyOn`} onChange={e => changeEventResetOtherValue(`${selector}.monthlyOn`)} component={renderSelect} options={weekDays} emptyOptionValue="true" />
                    </td>
                </tr>
                <tr>
                    <td>Monthly on</td>
                    <td> <Field name={`${selector}.monthlyOn`} onChange={e => changeEventResetOtherValue(`${selector}.weeklyOn`)} component={renderSelect} options={dates} emptyOptionValue="true" /></td>
                </tr>
            </tbody>
        </table>
    </div>)
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderFrequencySettings);