import React from 'react'
import { Field, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import { renderSelect } from './select'


const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {
    const readonly = selector(state, `${ownProps.basicSettingsSelector}.invoiceType`) == 2;
    return {
        readonly: readonly,
        shouldAggregateOn: selector(state, `${ownProps.selector}.aggregationType`) == 3
    }
}

const mapDispatchToProps = (dispatch, state) => {
    return {
        _onChange: (event, specificationSettingsSelector) => { 
            if (event.target.value == 1) { 
                dispatch(change('fieldArrays', `${specificationSettingsSelector}.specificationSetting`, "1"))
            }
        }
    }
}

const RenderAggregateSettings = ({ selector, aggregationTypes, mappingTypes, shouldAggregateOn, readonly, _onChange, specificationSettingsSelector }) => {

    return (<div className="settings">
        <b>Aggregation</b>
        <table className="table table-fluid">
            <tbody>
                <tr>
                    <td>
                        <Field name={`${selector}.aggregationType`} onChange={e => _onChange(e, specificationSettingsSelector)} component={renderSelect} options={aggregationTypes} label="Aggregation" disabled={readonly} />
                    </td>
                </tr>
            </tbody>
        </table>
        {shouldAggregateOn && <div>
            <RenderAggregateOn selector={`${selector}.aggregateOnSettings`} mappingTypes={mappingTypes} />
        </div>}

    </div>)
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderAggregateSettings);


const RenderAggregateOn = ({ selector, mappingTypes }) => {
    return (<div className="settings">
        <i>Aggregate On</i>
        <table className="table table-fluid">
            <tbody>
                <tr>
                    <td>
                        <Field name={`${selector}.field1`} component={renderSelect} options={mappingTypes} label="Field 1" emptyOptionValue="true" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Field name={`${selector}.field2`} component={renderSelect} options={mappingTypes} label="Field 2" emptyOptionValue="true" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Field name={`${selector}.field3`} component={renderSelect} options={mappingTypes} label="Field 3" emptyOptionValue="true" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    )
}
