import React from 'react'
import { FieldArray, reduxForm, arrayPush, reset } from 'redux-form'
import { connect } from 'react-redux'
import RenderConfigurationBody from './configurationbody'
import RenderCustomers from '../rendercomponents/rendercustomers'
import AddCustomer from '../components/customer/addCustomerForm'

const validateEmail = emailInput => {
  var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  var emailAdresses = emailInput.split(/,|;/);
  for (let index = 0; index < emailAdresses.length; index++) {
    const element = emailAdresses[index];
    if (!regex.test(element)) {
      return false;
    }
  }

  return true;
}


const validate = values => {

  const errors = {}

  if (!values.customers || values.customers.length <= 0) {
    errors.customers = { _error: 'At least one customer needs to be added' }
  }

  if (!values.configurations || values.configurations.length <= 0) {
    errors.configurations = { _error: 'At least one configuration needs to exsist for agreement' }
  } else {
    const configurationArrayErrors = []

    values.configurations.forEach((configuration, configurationIndex) => {
      const configurationError = {}

      if (!configuration.basicSettings || !configuration.basicSettings.description) {

        if (!configurationError.basicSettings)
          configurationError.basicSettings = {}

        configurationError.basicSettings.description = "Required"
        configurationArrayErrors[configurationIndex] = configurationError
      }



      if (!configuration.specificationSettings || !configuration.specificationSettings.specificationSetting) {

        if (!configurationError.specificationSettings)
          configurationError.specificationSettings = {}

        configurationError.specificationSettings.specificationSetting = "Specification settings are required"
        configurationArrayErrors[configurationIndex] = configurationError

      } else {
        if (configuration.specificationSettings.specificationSetting == 0) {
          //Is OnlySendSpecification, specification email required
          if (!configuration.specificationSettings.specificationEmail) {

            if (!configurationError.specificationSettings)
              configurationError.specificationSettings = {}

            configurationError.specificationSettings.specificationEmail = "Required"
            configurationArrayErrors[configurationIndex] = configurationError

          } else if (!validateEmail(configuration.specificationSettings.specificationEmail)) {

            if (!configurationError.specificationSettings)
              configurationError.specificationSettings = {}

            configurationError.specificationSettings.specificationEmail = 'Invalid email address'
            configurationArrayErrors[configurationIndex] = configurationError
          }
        }
      }

      var invoiceType = configuration.basicSettings.invoiceType;

      if (invoiceType == 2 || (invoiceType == 1 && configuration.basicSettings.overrideAmount > 0)) {

        if (!configuration.headerMappingSingleInvoiceSettings) {
          if (!configurationError.headerMappingSingleInvoiceSettings)
            configurationError.headerMappingSingleInvoiceSettings = {}

          configurationError.headerMappingSingleInvoiceSettings.header1 = ' '
          configurationError.headerMappingSingleInvoiceSettings.fixedValue1 = ' '
          configurationError.headerMappingSingleInvoiceSettings.header2 = ' '
          configurationError.headerMappingSingleInvoiceSettings.fixedValue2 = ' '
          configurationError.headerMappingSingleInvoiceSettings.header3 = ' '
          configurationError.headerMappingSingleInvoiceSettings.fixedValue3 = ' '
          configurationError.headerMappingSingleInvoiceSettings.objectError = 'Header mapping single invoice are required to set at least one value when is collective and override to single amount is set'

          configurationArrayErrors[configurationIndex] = configurationError
        } else {

          if ((!configuration.headerMappingSingleInvoiceSettings.header1 || configuration.headerMappingSingleInvoiceSettings.header1 == -1) &&
            (!configuration.headerMappingSingleInvoiceSettings.header2 || configuration.headerMappingSingleInvoiceSettings.header2 == -1) &&
            (!configuration.headerMappingSingleInvoiceSettings.header3 || configuration.headerMappingSingleInvoiceSettings.header3 == -1) &&
            (!configuration.headerMappingSingleInvoiceSettings.fixedValue1 || configuration.headerMappingSingleInvoiceSettings.fixedValue1.length <= 0) &&
            (!configuration.headerMappingSingleInvoiceSettings.fixedValue2 || configuration.headerMappingSingleInvoiceSettings.fixedValue2.length <= 0) &&
            (!configuration.headerMappingSingleInvoiceSettings.fixedValue3 || configuration.headerMappingSingleInvoiceSettings.fixedValue3.length <= 0)) {

            if (!configurationError.headerMappingSingleInvoiceSettings)
              configurationError.headerMappingSingleInvoiceSettings = {}

            configurationError.headerMappingSingleInvoiceSettings.header1 = ' '
            configurationError.headerMappingSingleInvoiceSettings.fixedValue1 = ' '
            configurationError.headerMappingSingleInvoiceSettings.header2 = ' '
            configurationError.headerMappingSingleInvoiceSettings.fixedValue2 = ' '
            configurationError.headerMappingSingleInvoiceSettings.header3 = ' '
            configurationError.headerMappingSingleInvoiceSettings.fixedValue3 = ' '
            configurationError.headerMappingSingleInvoiceSettings.objectError = 'Header mapping single invoice are required to set at least one value when is collective and override to single amount is set'

            configurationArrayErrors[configurationIndex] = configurationError
          }
        }

      }

      if (invoiceType == "1") {
        //is collective

        if (configuration.frequencySettings.weeklyOn == -1 && configuration.frequencySettings.monthlyOn == -1) {

          if (!configurationError.frequencySettings)
            configurationError.frequencySettings = {};

          configurationError.frequencySettings.weeklyOn = "Weekly or Monthly are required to be set when configuration invoice type is collective"
          configurationError.frequencySettings.monthlyOn = "Monthly or Weekly are required to set when configuration is collective"

          configurationArrayErrors[configurationIndex] = configurationError
        }


        if (!configuration.headerMappingCollectiveInvoiceSettings) {

          if (!configurationError.headerMappingCollectiveInvoiceSettings)
            configurationError.headerMappingCollectiveInvoiceSettings = {}

          configurationError.headerMappingCollectiveInvoiceSettings.objectError = 'Header mapping collective invoice are required to set at least one value when is collective invoice type'
          configurationArrayErrors[configurationIndex] = configurationError
        } else {

          if ((!configuration.headerMappingCollectiveInvoiceSettings.header1 || configuration.headerMappingCollectiveInvoiceSettings.header1 == -1) &&
            (!configuration.headerMappingCollectiveInvoiceSettings.header2 || configuration.headerMappingCollectiveInvoiceSettings.header2 == -1) &&
            (!configuration.headerMappingCollectiveInvoiceSettings.header3 || configuration.headerMappingCollectiveInvoiceSettings.header3 == -1) &&
            (!configuration.headerMappingCollectiveInvoiceSettings.fixedValue1 || configuration.headerMappingCollectiveInvoiceSettings.fixedValue1.length <= 0) &&
            (!configuration.headerMappingCollectiveInvoiceSettings.fixedValue2 || configuration.headerMappingCollectiveInvoiceSettings.fixedValue2.length <= 0) &&
            (!configuration.headerMappingCollectiveInvoiceSettings.fixedValue3 || configuration.headerMappingCollectiveInvoiceSettings.fixedValue3.length <= 0)) {

            if (!configurationError.headerMappingCollectiveInvoiceSettings)
              configurationError.headerMappingCollectiveInvoiceSettings = {}

            configurationError.headerMappingCollectiveInvoiceSettings.header1 = ' '
            configurationError.headerMappingCollectiveInvoiceSettings.fixedValue1 = ' '
            configurationError.headerMappingCollectiveInvoiceSettings.header2 = ' '
            configurationError.headerMappingCollectiveInvoiceSettings.fixedValue2 = ' '
            configurationError.headerMappingCollectiveInvoiceSettings.header3 = ' '
            configurationError.headerMappingCollectiveInvoiceSettings.fixedValue3 = ' '

            configurationError.headerMappingCollectiveInvoiceSettings.objectError = 'Header mapping collective invoice are required to set at least one value when is collective invoice type'
            configurationArrayErrors[configurationIndex] = configurationError
          }
        }



        if (!configuration.collectByFieldsSettings) {

          if (!configurationError.collectByFieldsSettings)
            configurationError.collectByFieldsSettings = {}

          configurationError.collectByFieldsSettings.objectError = 'Collect by fields settings are required to set at least one value when is collective invoice type'
          configurationArrayErrors[configurationIndex] = configurationError
        } else {

          if ((!configuration.collectByFieldsSettings.field1 || configuration.collectByFieldsSettings.field1 == -1) &&
            (!configuration.collectByFieldsSettings.field2 || configuration.collectByFieldsSettings.field2 == -1) &&
            (!configuration.collectByFieldsSettings.field3 || configuration.collectByFieldsSettings.field3 == -1)) {

            if (!configurationError.collectByFieldsSettings)
              configurationError.collectByFieldsSettings = {}

              configurationError.collectByFieldsSettings.field1 = ' '
              configurationError.collectByFieldsSettings.field2 = ' '
              configurationError.collectByFieldsSettings.field3 = ' '

            configurationError.collectByFieldsSettings.objectError = 'Collect by fields settings are required to set at least one value when is collective invoice type'
            configurationArrayErrors[configurationIndex] = configurationError
          }
        }

      }


      if (configuration.configurableFieldSettings) {

        for (var field in configuration.configurableFieldSettings) {
          var currentField = configuration.configurableFieldSettings[field];
          if (currentField) {

            if (currentField.showInCheckout && !currentField.isMeetingType) {

              if (!currentField.englishLanguage) {

                if (!configurationError.configurableFieldSettings)
                  configurationError.configurableFieldSettings = {}

                if (!configurationError.configurableFieldSettings[field])
                  configurationError.configurableFieldSettings[field] = {}

                configurationError.configurableFieldSettings[field].englishLanguage = "Required"
                configurationArrayErrors[configurationIndex] = configurationError
              }
              if (!currentField.localLanguage) {

                if (!configurationError.configurableFieldSettings)
                  configurationError.configurableFieldSettings = {}

                if (!configurationError.configurableFieldSettings[field])
                  configurationError.configurableFieldSettings[field] = {}

                configurationError.configurableFieldSettings[field].localLanguage = "Required"
                configurationArrayErrors[configurationIndex] = configurationError
              }
            }

            if (currentField.type == 1) {

              if (!currentField.values || currentField.values.length <= 0) {

                if (!configurationError.configurableFieldSettings)
                  configurationError.configurableFieldSettings = {}

                if (!configurationError.configurableFieldSettings[field])
                  configurationError.configurableFieldSettings[field] = {}

                configurationError.configurableFieldSettings[field].values = { _error: "At least one item have to be added to the list" }
                configurationArrayErrors[configurationIndex] = configurationError
              } else {

                const collectByFieldArrayErrors = []
                currentField.values.forEach((value, index) => {
                  const valueError = {};
                  if (!value.localLanguage || value.localLanguage.length <= 0) {
                    valueError.localLanguage = 'Required'
                    collectByFieldArrayErrors[index] = valueError
                  }
                  if (!value.englishLanguage || value.englishLanguage.length <= 0) {
                    valueError.englishLanguage = 'Required'
                    collectByFieldArrayErrors[index] = valueError
                  }

                })

                if (collectByFieldArrayErrors.length > 0) {

                  if (!configurationError.configurableFieldSettings)
                    configurationError.configurableFieldSettings = {}

                  if (!configurationError.configurableFieldSettings[field])
                    configurationError.configurableFieldSettings[field] = {}


                  configurationError.configurableFieldSettings[field].values = collectByFieldArrayErrors
                  configurationArrayErrors[configurationIndex] = configurationError
                }

              }
            }

          }

        }
      }
      //configurable field settings ends 

    })


    if (configurationArrayErrors.length) {
      errors.configurations = configurationArrayErrors
    }


    //validation ending
  }

  return errors
}


const mapDispatchToProps = (dispatch, state) => {
  return {
    handleAddCustomerSubmit: (values) => {
      dispatch(arrayPush('fieldArrays', 'customers', {
        number: values.customerNumber,
        name: values.customerName,
        configurationId: values.configurationId,
      }))
      dispatch(reset('AddCustomerForm'))
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    allowEditing: state.loadreducer.allowedEditing,
    initialValues: {
      configurations: state.configurations,
      customers: state.customers
    },
    configurationValues: state.configurationValues
  }
}


const renderLabel = ({ input, label, type, meta: { touched, error } }) => (
  <input className="readonly_input" readOnly="readonly" {...input} type="text" />
)


let FieldArraysForm = props => {
  const { configurationValues, initialValues, handleSubmit, pristine, reset, submitting, addConfigurationClick, allowEditing, handleAddCustomerSubmit } = props
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FieldArray name="configurations" component={RenderConfigurationBody} configurationValues={configurationValues} allowEditing={allowEditing} />
        <FieldArray name="customers" component={RenderCustomers} allowEditing={allowEditing} />
      </form>
      {allowEditing &&
        <AddCustomer onSubmit={handleAddCustomerSubmit} />
      }
    </div>
  )
}

FieldArraysForm = reduxForm({
  form: 'fieldArrays',
  validate,
  onSubmitFail: (errors) => {
    console.log(errors)
    if (errors) {
      alert("There are errors in the form that have to be fixed before saving!")
    }
  }
})(FieldArraysForm)

export default connect(mapStateToProps, mapDispatchToProps)(FieldArraysForm)


