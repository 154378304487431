import React from 'react'
import { Field } from 'redux-form'

export const renderSelect = (field) => {
  let _class = 'form-control';
  if (field.className) {
    _class += field.className;
  }

  var hasError = (field.meta != null && field.meta.error != null)

  if (hasError) {
    _class += " is-invalid"
  }

  const emptyValue = {
    name: 'Select value ...',
    value: -1
  }

  return (
    <div>
      <Field {...field.input} component="select" className={_class} disabled={field.disabled}>
        {field.emptyOptionValue && <option value={emptyValue.value}>{emptyValue.name}</option>}
        {
          field.options.map(x => (
            <option value={x.value} key={x.value}>
              {x.name}
            </option>
          ))}
      </Field>

      {field.touched && field.error && <div className="error">{field.error}</div>}
    </div>
  )
}


export const renderField = ({ input, label, className, type, meta: { touched, error } }) => {
  let _class = 'form-control';
  if (className) {
    _class += className;
  }
  if (error) {
    _class += " is-invalid"
  }

  return (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} type={type} placeholder={label} className={_class} />
        {touched && error && <span className="is-invalid-help-text">{error}</span>}
      </div>
    </div>
  )
}

export const renderRadioButton = () => {

}

export const errorMessage = ({ meta }) => {
 
  return (<div>{meta.error && <span className="is-invalid-help-text">{meta.error}</span>}</div>)
}

export const errorMessageObjectLevel = ({ meta }) => {
  
  return (<div>{meta.error && <span className="is-invalid-help-text">{meta.error}</span>}</div>)
}

export const renderTextBox = ({ input, label, className, type, meta: { touched, error }, disabled }) => {
  let _class = 'form-control';
  if (className) {
    _class += className;
  }

  if (error) {
    _class += " is-invalid"
  }

  return (
    <div>
      <input {...input} type={type} placeholder={label} className={_class} disabled={disabled === undefined ? false : true} />
      {touched && error && <span className="is-invalid-help-text">{error}</span>}
    </div>
  )
}



export const renderReadOnlyTextBox = ({ input, label, className, type, meta: { touched, error } }) => {
  let _class = 'form-control';
  if (className) {
    _class += className;
  }
  return (
    <div>
      <input {...input} type={type} placeholder={label} className={_class} disabled="disabled" />
      {touched && error && <span>{error}</span>}
    </div>
  )
}


export const renderCheckbox = ({ input, label, className, type, meta: { touched, error } }) => {
  let _class = '';
  if (className) {
    _class += className;
  }
  return (
    <div>
      <label>
        <input {...input} type={type} placeholder={label} className={_class} />{' '}
        {label}
      </label>
      <div>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
}