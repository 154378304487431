import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import {renderSelect, renderTextBox, errorMessageObjectLevel} from './select'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {
    var overrideAmount = selector(state, `${ownProps.basicSettings}.overrideAmount`);

    var invoiceType = selector(state, `${ownProps.basicSettings}.invoiceType`);

    if(invoiceType == 2)
        overrideAmount = 1

    if(!overrideAmount)
    overrideAmount = 0;

    return {
        header1Disabled: selector(state, `${ownProps.selector}.fixedValue1`) != null && selector(state, `${ownProps.selector}.fixedValue1`).length > 0,
        header2Disabled: selector(state, `${ownProps.selector}.fixedValue2`) != null && selector(state, `${ownProps.selector}.fixedValue2`).length > 0,
        header3Disabled: selector(state, `${ownProps.selector}.fixedValue3`) != null && selector(state, `${ownProps.selector}.fixedValue3`).length > 0,
        showComponent: overrideAmount > 0
    }
}

const RenderHeaderMappingSingleInvoiceSettings = ({ selector, mappingTypes, header1Disabled, header2Disabled, header3Disabled, showComponent }) => {

    return (showComponent && 
        <div className="settings">
            <b>Header mapping single invoice</b>
            
            <Field name={`${selector}.objectError`} component={errorMessageObjectLevel} />

            <table className="table table-fluid">
                <tbody>
                    <tr>
                        <td>Header 1:</td><td>
                            <Field name={`${selector}.header1`} component={renderSelect} options={mappingTypes}  disabled={header1Disabled} emptyOptionValue="true"/>
                        </td>
                        <td>
                            <Field name={`${selector}.fixedValue1`} type="text" component={renderTextBox} label="Fixed Value" emptyOptionValue="true"/>   
                        </td>
                    </tr>
                    <tr>
                        <td>Header 2:</td><td>
                            <Field name={`${selector}.header2`} component={renderSelect} options={mappingTypes} disabled={header2Disabled} emptyOptionValue="true"/>
                        </td>
                        <td>
                            <Field name={`${selector}.fixedValue2`} type="text" component={renderTextBox} label="Fixed Value" />   
                        </td>
                    </tr>
                    <tr>
                        <td>Header 3:</td><td>
                            <Field name={`${selector}.header3`} component={renderSelect} options={mappingTypes} disabled={header3Disabled} emptyOptionValue="true"/>
                        </td>
                        <td>
                            <Field name={`${selector}.fixedValue3`} type="text" component={renderTextBox} label="Fixed Value" />   
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


export default connect(mapStateToProps)(RenderHeaderMappingSingleInvoiceSettings);
