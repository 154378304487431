
const loadreducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case 'RECEIVE_INITALSTATE':
            return { ...action.state, status: action.status };
        case 'REQUEST_FAILED':
            return { ...action.state, status: action.status, statusText: action.statusText };
        default:
            return state
    }
};

export default loadreducer;