import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { renderTextBox, renderSelect } from '../../rendercomponents/select'


const selector = formValueSelector('fieldArrays')

const mapStateToProps = state => {

    function stringIsNullOrEmpty(str){
        return str === null || str.match(/^ *$/) !== null;
    }

    var formIsValid = false;

    if (state.form && state.form.AddCustomerForm) {
        var values = state.form.AddCustomerForm.values;
        formIsValid = values.configurationId != -1 && !stringIsNullOrEmpty(values.customerNumber) && !stringIsNullOrEmpty(values.customerName);
    }

    return {
        configurations: selector(state, `configurations`),
        initialValues: {
            customerNumber: '',
            customerName: '',
            configurationId: -1
        },
        formIsValid
    }
}

const mapDispatchToProps = (dispatch, state) => {
    return {
     
    }
}


let AddCustomerForm = ({ onAddClick, configurations, disableButton, handleSubmit, isFormValid, formIsValid }) => {
    const configurationList = configurations.map(function (item, key) {
        return { value: item.id, name: item.basicSettings.description }
    });

    return (
        <div className="row">
        <form onSubmit={values => handleSubmit(values)}>
            <table  className="table table-fluid">
                <tbody>
                    <tr>
                        <td>
                            <Field name="customerNumber" type="text" component={renderTextBox} label="Customer number" />
                        </td>
                        <td>
                            <Field name="customerName" type="text" component={renderTextBox} label="Customer name" />
                        </td>
                        <td>
                            <Field name="configurationId" component={renderSelect} options={configurationList} emptyOptionValue="true" />
                        </td>
                        <td>
                            <button type="submit" className="btn btn-success pull-right btn-sm" disabled={!formIsValid}>
                                <span className="glyphicon glyphicon-plus"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
        </div>
    )
}

AddCustomerForm = reduxForm({
    form: 'AddCustomerForm'
})(AddCustomerForm)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCustomerForm)