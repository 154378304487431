
const customers = (state = [], action) => {
    switch (action.type) {
        case 'RECEIVE_INITALSTATE':
            return action.state.customers;
        case 'ADD_CUSTOMER':
            return [
                ...state,
                {number : action.number, name: action.name, configurationId: action.configuration}
            ]
            case 'REMOVE_CUSTOMER':
            return  state.filter((customer, index) => index !== action.index)
            
        default:
            return state
    }
};

export default customers;