
export const changeConfigurationReducer = (state = null, action) => {
    switch (action.type) {
        case 'CHANGE_CONFIGURATION':
            action.configurations[action.fromIndex] = action.currentConfiguration;
            return {
                currentConfiguration: action.configurations[action.toIndex],
                fromIndex: action.fromIndex,
                toIndex: action.toIndex
            }
        case 'RECEIVE_INITALSTATE':
            return {
                currentConfiguration: action.state.configurations[0],
                fromIndex: 0,
                toIndex: 0
            }
        default:
            return state
    }
};


export const configurationContainerReducer = (state = [], action) => {
    switch (action.type) {
        case 'RECEIVE_INITALSTATE':
       
            return action.state.configurations;
         case 'CHANGE_CONFIGURATION':
        
            return state;
        default:
            return state
    }
};

export const configurationValuesReducer = (state = [], action) => {
    switch (action.type) {
        case 'RECEIVE_INITALSTATE':
            return action.state.configurationValues;
        default:
            return state
    }
};
