
const history = (state = [], action) => {
    switch (action.type) {
        case 'RECEIVE_INITALSTATE':
            return action.state.history
        case 'REQUEST_FAILED':
            return state;
        case 'NEW_VERSION':
            return [...state, { version: action.versionNumber, editedBy: action.editedBy, date: action.date }]
        default:
            return state
    }
};

export default history;