import React from 'react';
import thunkMiddleware from 'redux-thunk'
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import rootReducer from './reducers/rootReducer';
import { loadInitialState } from './actions/initialState'

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    if (localStorage.getItem('fake_realod') != null) {
        //localStorage.clear();
    }
});

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const token = getParameterByName('t');

if (token) {
    localStorage.setItem('access_token', token);
    localStorage.setItem('fake_realod', true);
    window.location = window.location.origin;
} else {
    localStorage.removeItem('fake_realod');
}

store.dispatch(loadInitialState())

ReactDOM.render(<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));
//registerServiceWorker();
unregister();