import React from 'react'
import { Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { renderSelect, renderTextBox, errorMessageObjectLevel } from '../rendercomponents/select'

const selector = formValueSelector('fieldArrays')

const mapStateToProps = (state, ownProps) => {

    const collectByFields = selector(state, ownProps.collectByFiledsSelector);

    const mappingValues = [];

    if(collectByFields)
    {
        if(collectByFields.field1 && collectByFields.field1 != -1)
        {
            mappingValues.push(ownProps.mappingTypes[parseInt(collectByFields.field1)])
        }

        if(collectByFields.field2 && collectByFields.field2 != -1)
        {
            mappingValues.push(ownProps.mappingTypes[parseInt(collectByFields.field2)])
        }

        if(collectByFields.field3 && collectByFields.field3 != -1)
        {
            mappingValues.push(ownProps.mappingTypes[parseInt(collectByFields.field3)])
        }
    }

    return {
        header1Disabled: selector(state, `${ownProps.selector}.fixedValue1`) != null && selector(state, `${ownProps.selector}.fixedValue1`).length > 0,
        header2Disabled: selector(state, `${ownProps.selector}.fixedValue2`) != null && selector(state, `${ownProps.selector}.fixedValue2`).length > 0,
        header3Disabled: selector(state, `${ownProps.selector}.fixedValue3`) != null && selector(state, `${ownProps.selector}.fixedValue3`).length > 0,
        isCollective: selector(state, `${ownProps.isCollectiveSelector}`) == 1,
        mappingValues: mappingValues
    }
}

const RenderHeaderMappingCollectiveInvoiceSettings = ({ selector, mappingTypes, header1Disabled, header2Disabled, header3Disabled, isCollective, mappingValues }) => {

    return (isCollective &&
        <div className="settings">
            <b>Header mapping collective invoice (only if collective)</b>
            
            <Field name={`${selector}.objectError`} component={errorMessageObjectLevel} />

            <table className="table table-fluid">
                <tbody>
                    <tr>
                        <td>Header 1:</td><td>
                            <Field name={`${selector}.header1`} component={renderSelect} options={mappingValues}  disabled={header1Disabled} emptyOptionValue="true"/>
                        </td>
                        <td>
                            <Field name={`${selector}.fixedValue1`} type="text" component={renderTextBox} label="Fixed Value" />   
                        </td>
                    </tr>
                    <tr>
                        <td>Header 2:</td><td>
                            <Field name={`${selector}.header2`} component={renderSelect} options={mappingValues} disabled={header2Disabled} emptyOptionValue="true"/>
                        </td>
                        <td>
                            <Field name={`${selector}.fixedValue2`} type="text" component={renderTextBox} label="Fixed Value" />   
                        </td>
                    </tr>
                    <tr>
                        <td>Header 3:</td><td>
                            <Field name={`${selector}.header3`} component={renderSelect} options={mappingValues} disabled={header3Disabled} emptyOptionValue="true"/>
                        </td>
                        <td>
                            <Field name={`${selector}.fixedValue3`} type="text" component={renderTextBox} label="Fixed Value" />   
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


export default connect(mapStateToProps)(RenderHeaderMappingCollectiveInvoiceSettings);
